import React, { useState } from 'react'
import styled from 'styled-components'
import { COMPONENT_LOOKUP } from '../../../theme/default'
import { v4 as uuidv4 } from 'uuid'
import { inject, observer } from 'mobx-react'


export const ComponentSelection = inject('store')(
  observer(({
    store,
    currentPage,
    setCurrentPage,
    setCurrentComponent,
    toggle,
    type = 'parent',
    handleAddComponent = (siteData, componentLabel) => {
      let newComponent = {}
      const lookup = COMPONENT_LOOKUP(siteData)
      for (const c in lookup) {
        if (lookup[c].label === componentLabel) {
          // this is gross but I was having deep copy issues with spread
          newComponent = JSON.parse(JSON.stringify(lookup[c].new))
          newComponent.id = uuidv4()
        }
      }
      const tempPage = { ...currentPage }
      if (!tempPage.data) {
        tempPage.data = []
      }
      tempPage.data.push(newComponent)
      if (newComponent.component === 'marketplace_hero') {
        tempPage.data.push(lookup['marketplace'].new)
      }
      setCurrentPage(tempPage)
      setCurrentComponent(tempPage[tempPage.length - 1])
      toggle()
    }
  }) => {
    const { siteData } = store
    const components = []
    const lookup = COMPONENT_LOOKUP(siteData)
    for (const c in lookup) {
      if (lookup[c].label && lookup[c].type.includes(type)) {
        components.push(lookup[c].label)
      }
    }

    return (
      <Wrapper>
        {components.map((c, i) => (
          <ComponentCard onClick={() => handleAddComponent(siteData, c)} key={i}>
            {c}
          </ComponentCard>
        ))}
      </Wrapper>
    )
  })
)

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const ComponentCard = styled.div`
  min-width: 150px;
  text-align: center;
  flex: 1;
  height: 169px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.lightGrey};
  }
`
