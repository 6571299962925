import { FormWrapper } from '../../../../../theme/default/base'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'

export const SplitTextBlockForm = ({
  contentLeft,
  contentRight,
  textColor,
  backgroundColor,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <ColorPicker
        name={'textColor'}
        label="Text Color"
        value={textColor}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'backgroundColor'}
        label="Background Color"
        value={backgroundColor}
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={contentLeft}
        name="contentLeft"
        label="Content Left"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={contentRight}
        name="contentRight"
        label="Content Right"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
