import React, { useState, useEffect } from 'react'
import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'
import { StandardInput } from '/components/admin/page/page_builder/form-inputs/Standard'
import { hasImage } from '../../utils'

export const SideImageForm = ({
  description,
  has_cta = true,
  cta_text = null,
  cta_url = null,
  new_window = false,
  bg_color = '#fff',
  text_color = 'black',
  title,
  reverse = false,
  image,
  imageLink = null,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <BooleanInput
        value={has_cta}
        name="has_cta"
        label="Call to action"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_text}
        name="cta_text"
        label="CTA Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={cta_url}
        name="cta_url"
        label="CTA Url"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <BooleanInput
        value={new_window}
        name="new_window"
        label="Open link in a new Window?"
        setCurrentComponent={setCurrentComponent}
      />
      <BooleanInput
        value={reverse}
        name="reverse"
        label="Reverse"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={description}
        name="description"
        label="Description"
        height="400px"
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        value={text_color}
        label="Text Color"
        name="text_color"
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        value={bg_color}
        label="Background Color"
        name="bg_color"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={image}
        label="Image"
        name="image"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={imageLink}
        name="imageLink"
        label="Image Link URL"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      {hasImage(image) && (
        <OpacityInput
          value={image?.opacity}
          label="Image Opacity"
          name="image"
          setCurrentComponent={setCurrentComponent}
        />
      )}
    </FormWrapper>
  )
}
