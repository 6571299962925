import { inject, observer } from 'mobx-react'
import { VisbilityContainer } from '../../../../../home/Animations'
import { Container, interpolate } from '../../../../../theme/default/base'
import styled from 'styled-components'

export const SplitTextBlock = inject('store')(
  observer(
    ({ store, contentLeft, contentRight, textColor, backgroundColor }) => {
      return (
        <Wrapper textColor={textColor} backgroundColor={backgroundColor}>
          <div className="content">
            <VisbilityContainer>
              <div className="content-wrapper">
                <Container>
                  <Split>
                    <Text
                      textColor={textColor}
                      dangerouslySetInnerHTML={{
                        __html: interpolate(store.siteData, contentLeft || '')
                      }}
                    />
                    <Text
                      textColor={textColor}
                      dangerouslySetInnerHTML={{
                        __html: interpolate(store.siteData, contentRight || '')
                      }}
                    />
                  </Split>
                </Container>
              </div>
            </VisbilityContainer>
          </div>
        </Wrapper>
      )
    }
  )
)

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 2rem 0;
`

const Text = styled.div`
  font-size: 1.2rem;
  color: ${(props) => props.textColor};

  h1,
  h2,
  h3 {
    color: ${(props) => props.textColor} !important;
  }
`

const Split = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  ${(props) => props.theme.media.phone`
    flex-direction: column;
  `}

  div {
    flex: 1;
    padding: 1rem;
  }
`
